<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label>Приглашение в НТО</template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div v-if="organization">
              <p class="mb-m">
                Вы приглашены присоединиться к&nbsp;организации &laquo;{{
                  organization.full_name || organization.short_name
                }}&raquo;.
              </p>
            </div>

            <div v-if="!pending">
              <div v-if="user">
                <div v-if="user.id && !isMentor">
                  <p class="mb-s">
                    Вы не можете принять это приглашение, потому что являетесь
                    участником Олимпиады. Только наставники могут принимать
                    приглашения в организацию.
                  </p>
                  <BaseButton
                    id="mentor-invite-user-index"
                    tag="router-link"
                    :to="{ name: 'user-index' }"
                    >В кабинет участника</BaseButton
                  >
                </div>
                <div v-else-if="user.id && isMentor">
                  <div v-if="!freeOrganizations.length">
                    <div
                      v-if="noParentOrganizations.length"
                      class="mb-s">
                      У вашей организации уже указано юридическое лицо. Удалите
                      юридическое лицо в личном кабинете и пройдите по этой
                      ссылке заново.
                    </div>
                    <div
                      v-else
                      class="mb-s">
                      У вашей организации уже есть дочерняя организация.
                      Обратитесь в
                      <a
                        :href="`mailto:${$store.state.supportEmail}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="link"
                        >службу поддержки</a
                      >
                    </div>
                    <BaseButton
                      id="mentor-invite-mentor-index"
                      tag="router-link"
                      :to="{ name: 'mentor-index' }"
                      >В кабинет Наставника</BaseButton
                    >
                  </div>
                  <div v-else>
                    <div
                      v-if="freeOrganizations.length > 1"
                      class="mb-s">
                      <div class="mb-s">
                        Мы нашли у вас несколько организаций. Выберите ту,
                        которую желаете присоединить:
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-xs-12 mb-s">
                          <BaseSelect
                            v-model="selectedOrganization"
                            :options="freeOrganizations"
                            :searchable="false"
                            :show-no-results="false"
                            :preselect-first="true"
                            label="full_name"
                            :allow-empty="false"
                            placeholder="Выбрать организацию"></BaseSelect>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                          <BaseButton
                            id="mentor-invite-accept"
                            :disabled="!!error"
                            @click="handleAccept"
                            >Принять приглашение</BaseButton
                          >
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="mb-s">
                        Ваша организация &laquo;{{
                          freeOrganizations[0].full_name
                        }}&raquo; будет добавлена как дочерняя организация.
                      </div>

                      <BaseButton
                        id="mentor-invite-accept"
                        :disabled="!!error"
                        @click="handleAccept"
                        >Принять приглашение</BaseButton
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="!user.id"
                  class="mt-s">
                  <BaseButton
                    id="mentor-invite-reg-and-accept"
                    :disabled="!!error"
                    @click="handleRegMentor"
                    >Принять приглашение</BaseButton
                  >
                </div>
              </div>
              <div v-else>
                <p class="mb-s">
                  Войдите в&nbsp;учетную запись &laquo;Талант&raquo;, чтобы
                  принять приглашение.
                </p>
                <BaseButton
                  id="mentor-invite-login"
                  tag="a"
                  :href="`${$store.state.clientApiPrefix}/auth/go?next=${$route.fullPath}`"
                  >Войти</BaseButton
                >
              </div>
            </div>

            <BaseSpinner v-else />

            <div v-if="error">
              <div class="form-error mb-s">{{ error }}</div>
            </div>
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page InviteOrganization
 * Экран инвайта дочерней организации в большую организацию
 * Получаем информацию о текущем пользователе и о его организациях
 * При переходе по этой ссылке пользователь, который является оунером организации, может принять приглашение если к его организации не прикреплено юридического лица и которая уже не является дочерней.
 */
import { mapGetters } from "vuex";
import { talentRequest, request } from "@/services/api";
import RoleCard from "@/components/registration/RoleCard.vue";

import { MENTOR_ROLE } from "@/constants";
export default {
  name: "Inviteorganization",
  components: { RoleCard },
  metaInfo() {
    return {
      title: "Приглашение в организацию",
    };
  },
  data() {
    return {
      organization: null,
      pending: true,
      error: "",
      userOrganizations: [],
      selectedOrganization: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
    }),
    noParentOrganizations() {
      // организация не имеет дочерних
      return this.userOrganizations.filter((item) => !item.parent_organization);
    },
    freeOrganizations() {
      // организация не имеет дочерних и не имеет юр. лица
      return this.noParentOrganizations.filter((item) => !item.legal_entity);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      // Получаем информацию об организации
      try {
        const { data: organization } = await talentRequest({
          method: "GET",
          url: `/api/organizations/${this.$route.params.org}/`,
        });
        this.organization = organization;
      } catch (error) {
        if (error.status === 404) {
          this.error =
            "Организация не найдена. Проверьте корректность ссылки, или попросите владельца организации выслать ссылку повторно.";
        } else {
          this.error = error.message;
        }
      }
      try {
        await this.$store.dispatch("user/getMe");
        if (this.user) {
          const { data: organizations } = await talentRequest({
            methods: "GET",
            url: `/api/users/${this.user.talent_id}/organizations/`,
            params: {
              limit: 50,
              offset: 0,
            },
          });
          this.userOrganizations = organizations.results;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.pending = false;
    },

    async acceptInvite() {
      const { user, selectedOrganization, freeOrganizations } = this;
      const { code, org } = this.$route.params;
      const organizationId = selectedOrganization
        ? selectedOrganization.id
        : freeOrganizations[0].id;
      try {
        await talentRequest({
          method: "POST",
          url: `/api/users/${user.talent_id}/organizations/${organizationId}/invite/`,
          params: { code: code, organization_id: org },
        });
      } catch (error) {
        this.error = error.message;
        throw error;
      }
    },
    async handleAccept() {
      try {
        await this.acceptInvite();
        this.$router.push({
          name: "mentor-index",
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    async handleRegMentor() {
      // Если приняли - то регистрируем ментора
      await request({
        method: "POST",
        url: "/user/register",
        data: {
          role: MENTOR_ROLE,
        },
      });

      // Авторизуем в онти
      await this.$store.dispatch("user/getMe");

      // Принимаем инвайт в организацию
      await this.acceptInvite();
      try {
        await this.$store.dispatch("mentor/getMyOrganizations", true);
      } catch (error) {
        // do nothing
      }
      // Идем на главную для менторов
      this.$router.push({
        name: "mentor-index",
      });
    },
  },
};
</script>
